import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LogoImg from "../assets/images/XNALabs_LogoVertical_Gradient.svg";

const LandingContainer = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          xs={"none"}
          sm={6} md={6}
          style={{ backgroundColor: "#001232" }}
          className="login_left_bg"
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Box>
              <img src={LogoImg} alt="xna labs logo" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingContainer;