import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const userExists = localStorage.getItem("usertoken");
  const isLoading = false;
  const isAuthenticated = userExists;

  if (isLoading) {
    return <>loading...</>;
  }

  if (!isAuthenticated) {
    return <Redirect to={"/login"} />;
  }

  return <>{children}</>;
}

export default AuthProtect;
