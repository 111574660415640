const Routes = {
    HOME:"/app",
    LOGIN: "/login",
    EDITOR: "/editor",
    CREATE_MANUAL_COURSE: "/app/createcourse",
    CREATE_GUIDED_COURSE: "/app/createxcourse",
    CREATE_AUTO_COURSE: "/app/createautocourse",
    EDIT_COURSE: "/app/editcourse",
    TOPIC: "/app/topic",
    CONTENT: "/app/content",
    CREATE_CONTENT: "/app/contentcreate",
    EDIT_CONTENT: "/app/contentedit",
    CIRCLE: "/app/circleprompt"
}

export default Routes;